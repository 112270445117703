<script setup lang="ts">

</script>

<template>
  <div col class="token-details">
    <div row items-center>
      <img size-40px src="/images/roma.webp" alt="roma logo">
      <span ml-10px flex-grow font-600 class="lh-150%">
        <!-- ROMA = {{ $n(0.05, { style: 'currency', currency: 'USD', notation: 'standard' }) }} -->
      </span>

      <!-- TODO: href -->
      <button class="btn-primary">
        Buy ROMA
      </button>
    </div>
    <div class="details" mt-20px col p-20px>
      <dl>
        <div>
          <dt>Next Change:</dt>
          <!-- <dd>23:59:59</dd> -->
          <dd>-</dd>
        </div>

        <div>
          <dt>Total supply:</dt>
          <!-- <dd>{{ $n(48_000_000_000) }}</dd> -->
          <dd>-</dd>
        </div>

        <div>
          <dt>
            Circulating supply:
          </dt>
          <dd>
            <!-- {{ $n(100_000_000) }} -->
            -
          </dd>
        </div>

        <div>
          <dt>Total Burned:</dt>
          <!-- <dd>{{ $n(0) }}</dd> -->
          <dd>-</dd>
        </div>

        <div>
          <dt>Market Cap:</dt>
          <!-- <dd>{{ $n(0, { style: 'currency', currency: 'USD', notation: 'standard' }) }}</dd> -->
          <dd>-</dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<style scoped lang="scss">
.details {
  @apply bg-#363639 rounded-8px;

  dl {
    & > div {
      @apply row justify-between;

      dt {
        @apply text-12px text-#6e6e71 lh-150%;
      }
      dd {
        @apply font-600 text-12px lh-150%;
      }

      &:not(:first-child) {
        @apply mt-10px;
      }
    }
  }
}
</style>
