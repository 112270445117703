<script setup lang="ts">

</script>

<template>
  <div class="missw" col>
    <p>Miss W</p>

    <!-- TODO: href -->
    <ul>
      <li>Swap</li>
      <li>Stake</li>
      <li>Vote</li>
      <li>Contestants</li>
      <li>Event Rules</li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.missw {
  & > p {
    @apply text-16px font-600;
  }
  ul {
    @apply mt-30px;
    li {
      @apply text-14px text-#c6c6c7;
      &:not(:first-child) {
        @apply mt-14px;
      }
    }
  }
}
</style>
