<script setup lang="ts">
import type { RouteNamedMap } from 'vue-router/auto-routes'

const menus: {
  name: keyof RouteNamedMap
  title: string
}[] = [{
  name: '/',
  title: 'Introduction',
}, {
  name: '/btc1000',
  title: '1499 BTC',
}, {
  name: '/miss-w',
  title: 'Miss W',
}, {
  name: '/vote',
  title: 'VOTE',
}, {
  name: '/sponsors',
  title: 'Sponsors',
}, {
  name: '/live',
  title: 'Live',
}, {
  name: '/join',
  title: 'Join',
}]

const { y } = useWindowScroll()

const op = ref()
</script>

<template>
  <nav fixed inset-x-0 top-0 z-1000 lt-lg:hidden>
    <div container row items-center py-4>
      <div w-100px>
        <img src="/images/logo.webp" mx-auto alt="MissW Logo" h-40px shrink-0>
      </div>

      <ul class="desktop bg-[rgba(0,0,0,0.71)] backdrop-blur-xl">
        <router-link
          v-for="menu, idx in menus" :key="idx"
          v-slot="{ href, navigate, isActive }"
          :to="{ name: menu.name }"
          custom
        >
          <li
            :class="[isActive && 'selected']"
          >
            <a :href="href" @click="navigate">{{ menu.title }}</a>
          </li>
        </router-link>
      </ul>

      <HeaderVoteNow />
    </div>
  </nav>

  <nav fixed inset-x-0 top-0 z-1000 h-64px lg:hidden :class="[y > 67 ? 'bg-[rgba(0,0,0,0.71)] backdrop-blur-xl' : 'bg-black']">
    <div h-full row items-center px-20px>
      <img src="/images/logo.webp" alt="MissW Logo" h-44px shrink-0>

      <div flex-grow />

      <a href="https://missweb3.app" target="_blank" class="p-[10px_16px]" btn-primary shrink-0 rounded-full color-black>
        Vote Now
      </a>

      <button class="bg-#2f2f33" ml-10px rounded-2 p-8px @click="event => op.toggle(event)">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M3.00058 5.0001C2.63994 4.995 2.3045 5.18448 2.12269 5.49597C1.94088 5.80747 1.94088 6.19273 2.12269 6.50422C2.3045 6.81572 2.63994 7.00519 3.00058 7.00009H21.0006C21.3612 7.00519 21.6967 6.81571 21.8785 6.50422C22.0603 6.19272 22.0603 5.80746 21.8785 5.49597C21.6967 5.18447 21.3612 4.995 21.0006 5.0001H3.00058ZM3.00058 11.0001C2.63994 10.995 2.3045 11.1845 2.12269 11.496C1.94088 11.8075 1.94088 12.1927 2.12269 12.5042C2.3045 12.8157 2.63994 13.0052 3.00058 13.0001H21.0006C21.3612 13.0052 21.6967 12.8157 21.8785 12.5042C22.0603 12.1927 22.0603 11.8075 21.8785 11.496C21.6967 11.1845 21.3612 10.995 21.0006 11.0001H3.00058ZM3.00058 17.0001C2.63994 16.995 2.3045 17.1845 2.12269 17.496C1.94088 17.8075 1.94088 18.1927 2.12269 18.5042C2.3045 18.8157 2.63994 19.0052 3.00058 19.0001H21.0006C21.3612 19.0052 21.6967 18.8157 21.8785 18.5042C22.0603 18.1927 22.0603 17.8075 21.8785 17.496C21.6967 17.1845 21.3612 16.995 21.0006 17.0001H3.00058Z" fill="#CE0000" />
        </svg>
      </button>

      <OverlayPanel ref="op" class="menu-overlay">
        <ul class="mobile">
          <router-link
            v-for="menu, idx in menus" :key="idx"
            v-slot="{ href, navigate, isActive }"
            :to="{ name: menu.name }"
            custom
          >
            <li
              :class="[isActive && 'selected']"
            >
              <a
                :href="href" @click="(event) => {
                  op.toggle()
                  navigate(event)
                }"
              >{{ menu.title }}</a>
            </li>
          </router-link>
        </ul>
      </OverlayPanel>
    </div>
  </nav>
</template>

<style scoped lang="scss">
ul.desktop {
  @apply row mx-auto max-w-[786px] w-full rounded-[36px] p-5px;
  li {
    @apply rounded-[28px] text-center flex-grow;
    a {
      @apply p-[7px_16px] inline-block;
    }

    &.selected {
      color: black;
      background-color: #ff0000;
    }
  }
}

ul.mobile {
  li {
    a {
      display: inline-block;
      padding: 10px 20px;
      width: 240px;
    }

    &:first-child {
      a {
        padding-top: 20px;
      }
    }
    &:last-child {
      a {
        padding-bottom: 20px;
      }
    }
  }
}
</style>

<style lang="scss">
.menu-overlay {
  margin-top: 1.5rem;
  padding: 0;
  @apply bg-[rgba(0,0,0,0.71)] backdrop-blur-xl;
  .p-overlaypanel-content {
    padding: 0;
  }
}
</style>
