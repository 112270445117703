<script setup lang="ts">

</script>

<template>
  <footer class="bg-[#1C1C1C]">
    <!--  -->
    <div container col>
      <div col pb-40px pt-50px md:row>
        <footer-token-details md:w-360px />

        <div ml-0 mt-12 row shrink-0 lg:ml-115px md:ml-75px md:mt-0>
          <footer-miss-web3 grow />

          <footer-community shrink-0 sm:mr-12 md:hidden />
        </div>

        <footer-community ml-0 mt-12 hidden lg:ml-100px md:ml-60px md:mt-0 md:block />
      </div>

      <div b-t-1 b-solid class="b-#444448" py-30px>
        <p text-center text-14px class="text-#6e6e71">
          © 2023 - 2024 MissWebƐ
        </p>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
</style>
