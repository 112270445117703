<script setup lang="ts">

</script>

<template>
  <a href="https://missweb3.app" w-100px target="_blank" b="[#ff0000] solid 1" class="cursor-pointer rounded-[28px] bg-[rgba(0,0,0,0.40)] p-[11px_0px] text-center">
    <span class="text-#f00 lh-1rem">
      Vote Now
    </span>
  </a>
</template>

<style scoped>

</style>
